import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export function Home() {
  return (
    <>
      <AuthenticatedTemplate>
        <ButtonGroup orientation="horizontal">
          <Button component={RouterLink} to="/profile" variant="contained" color="primary">Min profil</Button>
          <Button component={RouterLink} to="/points" variant="contained" color="primary">Points</Button>
        </ButtonGroup>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Typography variant="h6" align="center">Logg inn for å se ting og tang</Typography>
      </UnauthenticatedTemplate>
    </>
  );
}
