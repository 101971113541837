import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import { Theme } from "@mui/material/styles";

interface ThemeContextType {
  toggleTheme: () => void;
  theme: Theme;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useThemeContext must be used within a ThemeProvider");
  }
  return context;
};

interface ThemeContextProviderProps {
  children: React.ReactNode;
}

export const ThemeContextProvider: React.FC<ThemeContextProviderProps> = ({
  children,
}) => {
  // Check localStorage for the saved theme mode
  const savedMode = localStorage.getItem("themeMode") as
    | "light"
    | "dark"
    | null;
  const [mode, setMode] = useState<"light" | "dark">(savedMode || "light");

  // Save the theme mode to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("themeMode", mode);
  }, [mode]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          background: {
            paper: mode === "dark" ? "#1e1e1e" : "#f5f5f5",
          },
        },
      }),
    [mode],
  );

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  useEffect(() => {
    const root = document.documentElement;
    if (mode === "dark") {
      root.style.setProperty("--draw-color", theme.palette.text.primary);
      root.style.setProperty("--blank-color", theme.palette.background.default);
    } else {
      root.style.setProperty("--draw-color", theme.palette.text.primary);
      root.style.setProperty("--blank-color", theme.palette.background.default);
    }
  }, [mode]);

  return (
    <ThemeContext.Provider value={{ toggleTheme, theme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
