import React from "react";
import { useTheme } from "@mui/material/styles";

// Custom SVG icon for Router
const ServiceRouterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 59 40"
    fill="#fff"
    fillRule="evenodd"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    width="80"
    height="80"
  >
    <style>
      {`
          .B { stroke: none; }
          .C { stroke-linejoin: miter; }
          .D { fill: none; }
          .E { stroke: #fff; }
          .F { stroke-width: .394; }
          .G { fill: #036c9b; }
          .H { stroke-width: .788; }
        `}
    </style>
    <use xlinkHref="#ServiceRouterSymbol" x=".5" y=".5" />
    <symbol id="ServiceRouterSymbol" overflow="visible">
      <use xlinkHref="#ServiceRouterPathC" className="B G" />
      <use xlinkHref="#ServiceRouterPathC" className="C D E H" />
      <use xlinkHref="#ServiceRouterPathD" className="B G" />
      <use xlinkHref="#ServiceRouterPathD" className="C D E H" />
      <use xlinkHref="#ServiceRouterPathC" className="B G" />
      <use xlinkHref="#ServiceRouterPathC" className="C D E H" />
      <use xlinkHref="#ServiceRouterPathE" className="B G" />
      <use xlinkHref="#ServiceRouterPathE" className="C D E H" />
      <g className="B">
        <path d="M22.448 6.687l2.362 3.544-9.056 1.969 1.969-1.575L3.548 8.262l3.544-2.756 13.387 2.363 1.969-1.181zm12.6 8.662l-1.575-3.544 7.875-1.575-1.181 1.181 13.388 2.362-3.15 2.363-13.388-2.363-1.969 1.575zM30.323 4.719l9.45-2.362v3.938l-2.363-.787-4.331 3.938-4.331-.788 4.725-3.544-3.15-.394zm-3.544 14.175l-9.056 1.575v-3.937l2.363.394 4.725-3.937 4.331.394-5.119 4.725 2.756.788z" />
        <use xlinkHref="#ServiceRouterPathF" fill="#156287" />
      </g>
      <use
        xlinkHref="#ServiceRouterPathF"
        stroke="#8ec9e0"
        strokeWidth="1.181"
        className="C D"
      />
      <use xlinkHref="#ServiceRouterPathG" className="B" />
      <use xlinkHref="#ServiceRouterPathG" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathH" className="B" />
      <use xlinkHref="#ServiceRouterPathH" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathI" className="B" />
      <use xlinkHref="#ServiceRouterPathI" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathI" x="-2.363" className="B" />
      <use xlinkHref="#ServiceRouterPathI" x="-2.363" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathJ" className="B" />
      <use xlinkHref="#ServiceRouterPathJ" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathK" className="B" />
      <use xlinkHref="#ServiceRouterPathK" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathI" x="-8.663" className="B" />
      <use xlinkHref="#ServiceRouterPathI" x="-8.663" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathL" className="B" />
      <use xlinkHref="#ServiceRouterPathL" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathI" x="-12.994" className="B" />
      <use xlinkHref="#ServiceRouterPathI" x="-12.994" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathI" x="-14.963" className="B" />
      <use xlinkHref="#ServiceRouterPathI" x="-14.963" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathK" x="-10.631" className="B" />
      <use xlinkHref="#ServiceRouterPathK" x="-10.631" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathI" x="-19.294" className="B" />
      <use xlinkHref="#ServiceRouterPathI" x="-19.294" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathH" y="8.662" className="B" />
      <use xlinkHref="#ServiceRouterPathH" y="8.662" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathI" y="8.662" className="B" />
      <use xlinkHref="#ServiceRouterPathI" y="8.662" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathI" x="-2.363" y="8.662" className="B" />
      <use
        xlinkHref="#ServiceRouterPathI"
        x="-2.363"
        y="8.662"
        className="C D E F"
      />
      <use xlinkHref="#ServiceRouterPathJ" y="8.662" className="B" />
      <use xlinkHref="#ServiceRouterPathJ" y="8.662" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathK" y="8.662" className="B" />
      <use xlinkHref="#ServiceRouterPathK" y="8.662" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathI" x="-8.663" y="8.662" className="B" />
      <use
        xlinkHref="#ServiceRouterPathI"
        x="-8.663"
        y="8.662"
        className="C D E F"
      />
      <use xlinkHref="#ServiceRouterPathL" y="8.662" className="B" />
      <use xlinkHref="#ServiceRouterPathL" y="8.662" className="C D E F" />
      <use
        xlinkHref="#ServiceRouterPathI"
        x="-12.994"
        y="8.662"
        className="B"
      />
      <use
        xlinkHref="#ServiceRouterPathI"
        x="-12.994"
        y="8.662"
        className="C D E F"
      />
      <use
        xlinkHref="#ServiceRouterPathI"
        x="-14.963"
        y="8.662"
        className="B"
      />
      <use
        xlinkHref="#ServiceRouterPathI"
        x="-14.963"
        y="8.662"
        className="C D E F"
      />
      <use
        xlinkHref="#ServiceRouterPathK"
        x="-10.631"
        y="8.662"
        className="B"
      />
      <use
        xlinkHref="#ServiceRouterPathK"
        x="-10.631"
        y="8.662"
        className="C D E F"
      />
      <use
        xlinkHref="#ServiceRouterPathI"
        x="-19.294"
        y="8.662"
        className="B"
      />
      <use
        xlinkHref="#ServiceRouterPathI"
        x="-19.294"
        y="8.662"
        className="C D E F"
      />
      <use xlinkHref="#ServiceRouterPathG" x="-5.513" className="B" />
      <use xlinkHref="#ServiceRouterPathG" x="-5.513" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathG" x="-11.419" className="B" />
      <use xlinkHref="#ServiceRouterPathG" x="-11.419" className="C D E F" />
      <use xlinkHref="#ServiceRouterPathG" x="-16.931" className="B" />
      <use xlinkHref="#ServiceRouterPathG" x="-16.931" className="C D E F" />
    </symbol>
    <defs>
      <path
        id="ServiceRouterPathC"
        d="M57.881 11.025c0 6.3-12.994 11.419-29.137 11.419C12.994 22.444 0 17.325 0 11.025v16.537c0 6.3 12.994 11.419 28.744 11.419 16.144 0 29.137-5.119 29.137-11.419z"
      />
      <path
        id="ServiceRouterPathD"
        d="M28.744 22.444c16.144 0 29.137-5.119 29.137-11.419S44.887 0 28.744 0C12.994 0 0 4.725 0 11.025s12.994 11.419 28.744 11.419z"
      />
      <path
        id="ServiceRouterPathE"
        d="M28.743 22.444c16.144 0 29.137-5.119 29.137-11.419S44.887 0 28.743 0C12.994 0 0 4.725 0 11.025s12.994 11.419 28.743 11.419z"
      />
      <path
        id="ServiceRouterPathF"
        d="M16.148 24.406h25.988v11.813H16.148V24.406z"
      />
      <path
        id="ServiceRouterPathG"
        d="M35.836 27.556h3.544v5.119h-3.544v-5.119z"
      />
      <path
        id="ServiceRouterPathH"
        d="M39.38 25.194h1.181v1.181H39.38v-1.181z"
      />
      <path
        id="ServiceRouterPathI"
        d="M37.411 25.194h1.181v1.181h-1.181v-1.181z"
      />
      <path
        id="ServiceRouterPathJ"
        d="M33.08 25.194h1.181v1.181H33.08v-1.181z"
      />
      <path
        id="ServiceRouterPathK"
        d="M31.111 25.194h.788v1.181h-.788v-1.181z"
      />
      <path
        id="ServiceRouterPathL"
        d="M26.78 25.194h1.181v1.181H26.78v-1.181z"
      />
    </defs>
    <use xlinkHref="#ServiceRouterSymbol" x=".5" y=".5" />
  </svg>
);

// Custom SVG icon for Switch
const SwitchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 77 39"
    fill="#fff"
    fillRule="evenodd"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    width="80"
    height="80"
  >
    <g stroke="#fff" strokeLinejoin="miter" fill="#126d99" strokeWidth=".787">
      <path d="M56.662 36.594v-18.1H.394v18.1h56.268z" />
      <path d="M0 18.494L22.822 0h52.334L56.268 18.494H0zm56.662 18.887L75.156 16.92V0L56.662 18.494v18.887z" />
    </g>
    <path
      d="M33.053 13.772l-1.181.787H18.101l-1.574 1.574-3.935-1.574 7.87-2.361-1.574 1.574h14.166zm11.018-7.083L42.89 7.869H29.118l-1.574 1.18-3.935-1.574 7.87-1.967-1.574 1.18h14.166zm-8.657 4.722l1.18-.787H50.76l1.18-1.574 3.935 1.574-7.476 2.361 1.18-1.574H35.414zm7.476-7.083l1.181-.787h13.772l1.574-1.574 3.935 1.967-7.87 1.967 1.574-1.574H42.89z"
      stroke="none"
    />
  </svg>
);

// Custom SVG icon for MPLS Router
const MPLSRouterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 49 62"
    fill="#fff"
    fillRule="evenodd"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    width="80"
    height="80"
  >
    <style>
      {`.B{stroke-linejoin:miter}.C{stroke:none}.D{fill:#000}.E{stroke-linecap:square}.F{fill:none}.G{stroke:#fff}.H{stroke-width:.787}.I{stroke-width:2.361}`}
    </style>
    <symbol id="MPLSRouter" overflow="visible">
      <g fill="#046897" className="B G H">
        <path d="M0 60.981V22.425h42.49v38.556H0z" />
        <path d="M47.604 55.473V17.311l-5.114 5.115v38.555l5.115-5.508zM7.868 0L0 5.508h42.49L47.604 0H7.868z" />
        <path d="M0 22.425V5.508h42.49v16.917H0z" />
        <path d="M47.605 17.311V0L42.49 5.508v16.917l5.115-5.115z" />
      </g>
      <path d="M42.49 22.425H0z" className="C D" />
      <path d="M42.49 22.425H0" className="B E F G H" />
      <path d="M42.49 22.426l5.115-5.114z" className="C D" />
      <path d="M42.49 22.426l5.115-5.114" className="B E F G H" />
      <path
        d="M14.556 10.229V6.688H7.868v3.541h6.688zm15.737 0V6.688h6.688v3.541h-6.688zm-15.737 9.049v-3.541H7.868v3.541h6.688zm15.737 0v-3.541h6.688v3.541h-6.688z"
        className="C D"
      />
      <g className="B E F H">
        <path d="M12.59 9.049h20.851M12.59 17.704h20.851m-22.032 0l22.032-8.655" />
        <path d="M11.409 9.049l22.032 8.655" />
      </g>
      <path
        d="M13.77 11.41V7.869H7.082v3.541h6.688zm15.737 0V7.869h6.688v3.541h-6.688zM13.77 20.065v-3.541H7.082v3.541h6.688zm15.737 0v-3.541h6.688v3.541h-6.688z"
        className="C"
      />
      <g className="B E F G H">
        <path d="M11.409 9.442h21.245m-21.245 9.049h21.245" />
        <path d="M10.229 18.491l22.425-9.049" />
        <path d="M10.229 9.442l22.425 9.049" />
      </g>
      <path d="M9.836 51.539h7.868l10.229-21.245h7.082" className="B E F I" />
      <g className="C">
        <path
          d="M33.047 26.359v7.475l4.721-3.541-4.721-3.934zm-21.638 28.72v-7.475l-4.721 3.934 4.721 3.541z"
          className="D"
        />
        <path d="M10.229 55.866v-7.475l-4.328 3.541 4.328 3.934zM32.26 26.753v7.868l4.328-3.934-4.328-3.934z" />
        <path
          d="M11.409 33.834v-7.475l-4.721 3.934 4.721 3.541z"
          className="D"
        />
        <path d="M10.229 34.621v-7.475l-4.328 3.541 4.328 3.934z" />
      </g>
      <g className="B E F I">
        <path d="M35.408 51.538h-7.475L17.311 29.9h-6.295" />
        <g className="G">
          <path d="M35.014 51.932h-7.869L16.523 30.687H8.655" />
          <path d="M8.655 51.932h7.475l10.622-21.245h8.262" />
        </g>
      </g>
      <g className="C">
        <path d="M33.048 47.604v7.475l4.721-3.541-4.721-3.934z" className="D" />
        <path d="M32.261 48.391v7.475l4.328-3.541-4.328-3.934z" />
      </g>
    </symbol>
    <use xlinkHref="#MPLSRouter" x="0" y="0" />
  </svg>
);

// Custom SVG icon for Cloud
const CloudIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 131 76"
    fill="#fff"
    fillRule="evenodd"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    width="80"
    height="80"
  >
    <symbol id="cloudSymbol" overflow="visible">
      <path d="M44.721 8.565C22.287 4.236 10.086 15.65 12.054 25.883v.394C-5.657 29.425-.934 50.285 7.725 50.285h.787C6.15 60.124 27.01 69.964 38.424 64.847l.787-.787c3.936 9.052 18.892 10.233 33.061 10.627 12.595.394 19.679-.394 25.189-5.904h.787c20.073 1.574 29.125-12.201 24.796-22.041l1.181-.394c6.691-1.574 7.478-17.318-4.329-19.679l.394-.787c5.117-10.233-7.084-19.679-23.615-18.105l-1.181-.394C83.686-3.636 49.444-1.668 45.508 8.959z" />
    </symbol>
    <use xlinkHref="#cloudSymbol" x="0" y="0" />
  </svg>
);

// Custom SVG icon for CPE House
const CpeHouseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 78 67"
    fill="#fff"
    fillRule="evenodd"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    width="80"
    height="80"
  >
    <symbol id="CpeHouse" overflow="visible">
      <g stroke="#fff" strokeLinejoin="miter" fill="#126d99" strokeWidth=".788">
        <path d="M31.887 4.724L38.974 0h3.936l-.393 3.543-5.117 4.724-5.512-3.543zM5.511 38.187h49.997v27.164H5.511V38.187z" />
        <path d="M55.507 38.187l17.716-16.928v25.983L55.507 65.351V38.187z" />
        <path d="M64.563 59.842H61.02l-.393-24.407 3.15-1.181 1.574 1.574.393 20.864-1.181 3.15z" />
        <path d="M63.774 34.25l.788 25.588 6.693-7.48-2.755-23.621-4.724 5.512z" />
        <path d="M59.445 40.155L33.856 6.694 42.517.001l34.25 21.259-17.322 18.897z" />
        <path d="M63.383 25.983l3.937-3.936L65.35 6.3l-3.543 1.574v14.173l1.574 3.936z" />
        <path d="M57.477 20.472l5.905 5.512-.393-18.897-3.15-.393-2.362 13.779z" />
        <path d="M59.839 6.694l2.755 1.969 2.755-2.362-3.15-.788-2.362 1.181zm-23.621-1.97l23.621 35.431H0L31.888 4.724h4.331z" />
      </g>
      <use xlinkHref="#C" stroke="none" fill="#126d99" />
      <use
        xlinkHref="#C"
        stroke="#fff"
        strokeLinejoin="miter"
        fill="none"
        strokeWidth=".787"
      />
      <use xlinkHref="#D" stroke="none" fill="#126d99" />
      <use
        xlinkHref="#D"
        stroke="#fff"
        strokeLinejoin="miter"
        fill="none"
        strokeWidth=".787"
      />
      <path
        d="M27.557 38.187l1.575 1.968-5.118 1.181 1.181-.787-7.874-1.181 1.968-1.575 7.48 1.181.787-.787zm7.086 5.118l-.787-2.362 4.33-.787-.787.787 7.48 1.181-1.575 1.575-7.48-1.575-1.181 1.181zM32.281 37.4l5.118-1.575v2.362l-1.181-.394-2.756 1.968-2.362-.394 2.756-1.968h-1.575zm-2.362 7.48l-4.724 1.181v-2.362l1.181.394 2.756-2.362 2.362.394-2.756 2.362 1.181.394z"
        stroke="none"
      />
    </symbol>
    <defs>
      <path
        id="C"
        d="M47.235 40.949c0 3.149-7.086 5.905-15.747 5.905-9.054 0-16.141-2.756-16.141-5.905v9.055c0 3.149 7.086 5.905 16.141 5.905 8.661 0 15.747-2.756 15.747-5.905z"
      />
      <path
        id="D"
        d="M31.488 46.855c8.661 0 15.747-2.756 15.747-5.905 0-3.543-7.086-6.299-15.747-6.299-9.054 0-16.141 2.756-16.141 6.299 0 3.149 7.086 5.905 16.141 5.905z"
      />
    </defs>
    <use xlinkHref="#CpeHouse" x="0" y="0" />
  </svg>
);

const TextLinkIcon = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        transform: "translate(50%, -50%)",
      }}
    >
      <svg width="10" height="10" viewBox="0 0 10 10">
        <line
          x1="0"
          y1="10"
          x2="10"
          y2="0"
          style={{ stroke: theme.palette.text.primary, strokeWidth: 2 }}
        />
      </svg>
    </div>
  );
};

export {
  ServiceRouterIcon,
  SwitchIcon,
  MPLSRouterIcon,
  CloudIcon,
  CpeHouseIcon,
  TextLinkIcon,
};
