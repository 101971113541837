import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { acquireAccessToken } from "../utils/auth";
import { OpenAPI } from "../gen/cfsInventoryClient/core/OpenAPI";
import { CfsInventoryServicesService } from "../gen/cfsInventoryClient/services.gen";
import {
  CfsInventoryServicesGetServiceByIdData,
  ConfigureResponse,
  Service,
  ServiceByIdResponse,
} from "../gen/cfsInventoryClient/types.gen";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  List,
  ListItem,
  Divider,
  CardMedia,
} from "@mui/material";
import LanIcon from "@mui/icons-material/Lan";
import InfoIcon from "@mui/icons-material/Info";
import SpeedIcon from "@mui/icons-material/Speed";
import IconRouter from "@mui/icons-material/Router";
import BadgeIcon from "@mui/icons-material/Badge";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopy from "./ContentCopy";
import { EquipmentSummaryWithPopover } from "./Equipment";
import {
  ChangeNatureIcon,
  StatusIconConfigResponse,
  StatusIconConfigResponsesAggregated,
  ConfigResponseStatusErrors,
} from "./ConfigResponseStatus";

interface ServiceCardProps {
  id: string;
  service_type: string;
  display_name: string;
  changeSearchTerm: (searchTerm: string) => void;
  statusCallback: (id: string, status: ConfigureResponse[] | undefined) => void;
}

// https://digital.eviny.io/oss-cfsinventory/api/v1/service/id/69ed213f-8500-4122-a433-015d43294da0

const ServiceCard: React.FC<ServiceCardProps> = ({
  id,
  service_type,
  display_name,
  changeSearchTerm,
  statusCallback,
}) => {
  let isMounted = true;
  const [serviceResult, setServiceResult] = useState<Service | null>(null);
  const [expanded, setExpanded] = useState(false);
  const [consecutiveCompleteIntervals, setConsecutiveCompleteIntervals] =
    useState(0);
  const intervalId = React.useRef<NodeJS.Timeout | null>(null);

  const fetchData = async () => {
    const token = await acquireAccessToken();
    OpenAPI.TOKEN = token;
    const input: CfsInventoryServicesGetServiceByIdData = {
      serviceId: id,
    };
    const response =
      (await CfsInventoryServicesService.cfsInventoryServicesGetServiceById(
        input,
      )) as ServiceByIdResponse;
    setServiceResult(response.service);
    statusCallback(id, response.service.config_responses);

    // Check if all config_responses are completed or if there are no config_responses
    const allCompleted =
      (response.service.config_responses?.length === 0 ||
        response.service.config_responses?.every(
          (response) => response.status === "COMPLETED",
        )) ??
      true;

    if (allCompleted) {
      setConsecutiveCompleteIntervals((prev) => {
        const newCount = prev + 1;
        return newCount;
      });
    } else {
      setConsecutiveCompleteIntervals(0);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(async () => {
      await fetchData();
    }, 10000); // Fetch every 10 seconds

    intervalId.current = interval as NodeJS.Timeout;

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current); // Cleanup interval on component unmount
      }
    };
  }, []);

  useEffect(() => {
    if (consecutiveCompleteIntervals >= 3) {
      if (intervalId.current) {
        clearInterval(intervalId.current); // Clear interval if condition is met
      }
    }
  }, [consecutiveCompleteIntervals]);

  useEffect(() => {
    setConsecutiveCompleteIntervals(0);
  }, [id]);

  const configResponsesMap = new Map<string, ConfigureResponse[]>([
    ["default", serviceResult?.config_responses ?? []],
  ]);
  ConfigResponseStatusErrors({
    configresponseMap: configResponsesMap,
    serviceName: display_name,
  });
  return (
    <Card key={id}>
      <CardMedia />
      <CardContent>
        <Box sx={{ width: "100%", paddingBottom: "10px" }}>
          <Typography
            sx={{ fontSize: 14 }}
            color="text.secondary"
            align="right"
            gutterBottom
          >
            {service_type}
          </Typography>
          <Typography
            variant="h6"
            component="div"
            style={{ cursor: "pointer" }}
            onClick={() => changeSearchTerm(display_name)}
          >
            {display_name}{" "}
            <StatusIconConfigResponsesAggregated
              configResponsesMap={configResponsesMap}
            />
          </Typography>
        </Box>
        <Typography variant="h10" color="text.secondary">
          {serviceResult && serviceResult?.resource_equipment_ports && (
            <Box display="flex" alignItems="center">
              <LanIcon style={{ color: "blue" }} />
              <Box ml={1}>
                <EquipmentSummaryWithPopover
                  name={
                    serviceResult?.resource_equipment_ports[0]
                      .resource_equipment?.host_name ?? "Unknown hostname"
                  }
                  id={
                    serviceResult?.resource_equipment_ports[0]
                      .resource_equipment?.id ?? ""
                  }
                />
              </Box>
            </Box>
          )}
          {serviceResult && serviceResult?.resource_equipment_ports && (
            <Box display="flex" alignItems="center">
              <SettingsEthernetIcon />
              <Box ml={1}>
                {serviceResult?.resource_equipment_ports[0].display_name}
              </Box>
            </Box>
          )}
          <Box display="flex" alignItems="center">
            <AssignmentIcon />
            <Box ml={1}>
              {serviceResult && serviceResult?.reservation_ext_reference}
            </Box>
          </Box>
          {ServiceTypeInfo({ service: serviceResult })}
        </Typography>

        <Typography
          sx={{ fontSize: 14 }}
          color="text.secondary"
          align="right"
          gutterBottom
        >
          {expanded ? (
            <ExpandLessIcon onClick={() => setExpanded(false)} />
          ) : (
            <ExpandMoreIcon onClick={() => setExpanded(true)} />
          )}
        </Typography>
        {expanded && (
          <Box>
            <Box>
              <Typography sx={{ fontSize: 12 }} component="div">
                Service Id: {id}
                <ContentCopy value={id} />
              </Typography>
            </Box>
            {serviceResult?.parameters && (
              <Box>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  Parameters
                </Typography>
                <List>
                  {serviceResult?.parameters.map((param, index) => (
                    <div key={index}>
                      <Divider />
                      <ListItem>{param.name}</ListItem>
                      <ListItem>{param.type}</ListItem>
                      <ListItem>
                        {param.value}
                        <ContentCopy value={param.value} />
                      </ListItem>
                    </div>
                  ))}
                </List>
              </Box>
            )}
            <ConfigResponses
              configResponses={
                serviceResult?.config_responses as ConfigureResponse[]
              }
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

const ServiceTypeInfo = ({ service }) => {
  switch (service?.service_type) {
    case "IP_SUBNET":
      return <IpSubnetInfo service={service} />;
    case "ROUTE":
      return <RouteInfo service={service} />;
    case "VRF_ALLOCATION":
      return <VrfInfo service={service} />;
    case "INTERNET":
      return <SpeedInfoHack service={service} />;
    case "IP_VPN":
      return <SpeedInfoHack service={service} />;
    case "L2_P2P":
      return <SpeedInfoHack service={service} />;
    default:
      return null;
  }
};

const IpSubnetInfo = ({ service }) => (
  <Box display="flex" alignItems="center">
    <List sx={{ margin: 0, padding: 0 }}>
      {service?.parameters.map((param, index) => (
        <div key={index}>
          <ListItem sx={{ padding: 0 }}>
            <InfoIcon />
            <Box ml={1}>
              {param.name}: {param.value}
              <ContentCopy value={param.value} />
            </Box>
          </ListItem>
        </div>
      ))}
    </List>
  </Box>
);

const RouteInfo = ({ service }) => {
  const routingTypeParam = service?.parameters?.find(
    (param) => param.type === "CLASSIFIER" && param.name === "RoutingType",
  );
  const asNumber = service?.parameters?.find(
    (param) =>
      param.type === "IDENTIFIER" && param.name === "CustomersAsNumber",
  );

  return (
    <Box display="flex" alignItems="center">
      {routingTypeParam && (
        <Box display="flex" alignItems="center">
          <IconRouter />{" "}
          <Box ml={1}>
            {routingTypeParam.value} {asNumber && `AS${asNumber.value}`}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const VrfInfo = ({ service }) => {
  const vrfParam = service?.parameters?.find(
    (param) => param.type === "CLASSIFIER" && param.name === "VRF",
  );
  return (
    <Box display="flex" alignItems="center">
      {vrfParam && (
        <Box display="flex" alignItems="center">
          <BadgeIcon /> <Box ml={1}>{vrfParam.value}</Box>
        </Box>
      )}
    </Box>
  );
};

const SpeedInfoHack = ({ service }) => {
  // This is a hack to get the speed from the service policy name, should be able to get it from the service.
  const speedParam = service?.parameters?.find(
    (param) => param.type === "SVC_POLICY" && param.name === "IN",
  );
  const speedValue = speedParam ? speedParam.value.split("-")[1] : null;
  return (
    <Box display="flex" alignItems="center">
      {speedValue && (
        <Box display="flex" alignItems="center">
          <SpeedIcon /> <Box ml={1}>{speedValue}</Box>
        </Box>
      )}
    </Box>
  );
};

const ConfigResponses = ({
  configResponses,
}: {
  configResponses: ConfigureResponse[];
}) => {
  if (!configResponses || configResponses.length === 0) {
    return null;
  }
  return (
    <Box>
      <Typography sx={{ fontSize: 14 }} gutterBottom>
        Config responses
      </Typography>
      <Divider />
      <TableContainer>
        <Table>
          <TableBody>
            {configResponses
              .sort((a, b) => {
                const dateA = a.updated_at
                  ? new Date(a.updated_at).getTime()
                  : 0;
                const dateB = b.updated_at
                  ? new Date(b.updated_at).getTime()
                  : 0;
                return dateB - dateA;
              })
              .map((config) => {
                const formattedDate = config.updated_at
                  ? new Date(config.updated_at).toLocaleString("default", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    })
                  : "";
                return (
                  <TableRow>
                    <TableCell>
                      <StatusIconConfigResponse configResponse={config} />
                    </TableCell>
                    <TableCell>{config.template_name}</TableCell>
                    <TableCell>
                      <ChangeNatureIcon configResponse={config} />
                    </TableCell>
                    <TableCell>{formattedDate}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ServiceCard;
