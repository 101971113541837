import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID || '915a705b-2a6f-40a4-a749-ae8135dc2c53',
        authority: process.env.REACT_APP_AUTHORITY || 'https://login.microsoftonline.com/12f1bdca-9eec-45f6-a63e-2061b957e8ee',
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me?$select=onPremisesSamAccountName,officeLocation,department,companyName,displayName,userPrincipalName,jobTitle,mobilePhone",
    profileImage: "https://graph.microsoft.com/v1.0/me/photos/48x48/$value"
};

export const inventoryConfig = {
    scope: process.env.REACT_APP_INVENTORY_SCOPE || ["0b06ab06-a2e0-439d-9190-bdab1f28c285/Inventory.Manage"],
    baseUrl: process.env.REACT_APP_INVENTORY_BASE_URL || "https://api-gw.eviny.no/cfsInventory/pa"
}

export const custlineConfig = {
    scope: process.env.REACT_APP_CUSTLINE_SCOPE || ["716eee7a-a4ac-42c9-ab87-1ad44382750c/CustLine.Manage"],
    baseUrl: process.env.REACT_APP_CUSTLINE_BASE_URL ||"https://api-gw.eviny.no/cfsCustLine/pa"
}

export const internetConfig = {
    scope: process.env.REACT_APP_INTERNET_SCOPE || ["a41721f0-ed94-49cc-ae7b-81dc06d97d1a/Internet.Manage"],
    baseUrl: process.env.REACT_APP_INTERNET_BASE_URL ||"https://api-gw.eviny.no/cfsInternet/pa"
}

export const ipVpnConfig = {
    scope: process.env.REACT_APP_IPVPN_SCOPE || ["d39427a4-7180-4555-9a6c-cb0df72c37de/IP-VPN.Manage"],
    baseUrl: process.env.REACT_APP_IPVPN_BASE_URL ||"https://api-gw.eviny.no/cfsipvpn/pa"
}

export const l2CircuitConfig = {
    scope: process.env.REACT_APP_L2CIRCUIT_SCOPE || ["25d15c1a-db22-4b28-9446-a8de24c84240/L2Circuit.Manage"],
    baseUrl: process.env.REACT_APP_L2CIRCUIT_BASE_URL ||"https://api-gw.eviny.no/cfsL2Circuit/pa"
}




export const ipamConfig = {
    //scope: ["01bc2efb-ef12-435d-b089-ac1a578d1cba/Ipam.All"], // DEV

    scope: ["86c4aadc-6711-45d8-80ec-d4f82db29e34/Ipam.All"], // PROD
    //baseUrl: "https://digital.eviny.io/dev/ipam" // DEV
    // baseUrl: "https://digital.eviny.io/ipam" // PROD
    baseUrl: "https://api-gw.eviny.no/ipam/v1.2.1"
}

export const addressConfig = {
    apiKey: process.env.REACT_APP_ADDRESS_API_KEY || '',
    baseUrl: process.env.REACT_APP_SEARCH_BASE_URL || "https://api-gw.eviny.no/addressSearch/pa"
}

export const storageConfig = {
    scope: [
        process.env.REACT_APP_STORAGE_SCOPE || "https://corpportalconfigsa.blob.core.windows.net/.default"
    ],
    baseUrl: process.env.REACT_APP_STORAGE_URL || "https://corpportalconfigsa.blob.core.windows.net/"
}
