import { PublicClientApplication, PopupRequest, AuthenticationResult } from "@azure/msal-browser";
import { SetStateAction } from "react";
import { loginRequest, graphConfig, storageConfig } from "../authConfig";
import { msalInstance } from "../index";

export async function fetchApiKey(msalInstance: PublicClientApplication, loginRequest: PopupRequest, storageConfig: { scope: any; baseUrl: any; }, setApiKey: { (value: SetStateAction<string | null>): void; (arg0: any): void; }) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    let response: AuthenticationResult;
    try {

        response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account,
            scopes: storageConfig.scope
        });
    } catch (error) {
        console.error(error);
        response = await msalInstance.acquireTokenPopup({
            ...loginRequest,
            account: account,
            scopes: storageConfig.scope
        });
    }
    const url = new URL('/config/config.json', storageConfig.baseUrl).toString();
    const options = {
        headers: {
            "Authorization": `Bearer ${response.accessToken}`,
            "x-ms-version": "2019-07-07"
        }
    };
    const vaultResponse = await fetch(url, options);
    const responseText = await vaultResponse.text();

    try {
        const vaultData = JSON.parse(responseText);
        setApiKey(vaultData.address_api_key);
    } catch (error) {
        console.error("Error parsing JSON:", error);
    }
};

export async function callMsGraph() {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}



export enum ProfileSize {
    XS = 48,
    S = 64,
    M = 96,
    L = 120,
    XL = 240,
    XXL = 360
}

export async function getProfileImage(size: ProfileSize = ProfileSize.XS): Promise<Blob> {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    const profileImage = `https://graph.microsoft.com/v1.0/me/photos/${size}x${size}/$value`

    const resp = await fetch(profileImage, options)
    //  .then(response => response.blob())
    //  .catch(error => console.log(error));
    if (!resp.ok) {
        throw new Error("Network response was not ok");
    }
    const blob = await resp.blob()
    return blob
}