
import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from "@mui/material/IconButton";

interface ContentCopyProps {
    value?: string;
  }

  const ContentCopy: React.FC<ContentCopyProps> = ({ value = '' }) => {

    return (<IconButton onClick={() => navigator.clipboard.writeText(value)}>
    <ContentCopyIcon />
  </IconButton>
);

  }

  export default ContentCopy;