import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  InteractionStatus,
  InteractionRequiredAuthError,
  AccountInfo,
} from "@azure/msal-browser";
import React from "react";
import { useEffect } from "react";
import { getProfileImage } from "../utils/MsGraphApiCall";
import { loginRequest } from "../authConfig";
import Avatar from "@mui/material/Avatar";

const UserImage = () => {
  const { instance, inProgress } = useMsal();
  const [base64Image, setBase64Image] = useState<string | null>(null);

  useEffect(() => {
    if (!base64Image && inProgress === InteractionStatus.None) {
      getProfileImage()
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            setBase64Image(reader.result as string);
          };
          reader.readAsDataURL(blob);
        })
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount() as AccountInfo,
            });
          }
        });
    }
  }, [base64Image]);

  return <div>{base64Image ? <Avatar src={`${base64Image}`} /> : null}</div>;
};

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <div>
      <Tooltip title="Logout">
        <IconButton onClick={() => handleLogout()} color="inherit">
          <UserImage />
        </IconButton>
      </Tooltip>
    </div>
  );
};
