import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from "@mui/icons-material/Work";
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { ProfileSize, getProfileImage } from "../utils/MsGraphApiCall";
import { useMsal } from "@azure/msal-react";
import { AccountInfo, InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { theme } from "../styles/theme";


export type GraphData = {
    displayName: string,
    jobTitle: string,
    officeLocation: string,
    onPremisesSamAccountName: string,
    department: string,
    companyName: string,
    userPrincipalName: string,
    mobilePhone: string,
};

export type ProfileImage = {
    ImageValue: string
}

export const ProfileData: React.FC<{ graphData: GraphData }> = ({ graphData }) => {
    const { instance, inProgress } = useMsal();
    const [base64Image, setBase64Image] = useState<string | null>(null);

    useEffect(() => {
        if (!base64Image && inProgress === InteractionStatus.None) {
            getProfileImage(ProfileSize.XL).then(blob => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setBase64Image(reader.result as string);
                }
                reader.readAsDataURL(blob);
            }
            ).catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: instance.getActiveAccount() as AccountInfo
                    });
                }
            });

        }
    }, [base64Image]);
    
    return (
        <Card sx={{ maxWidth: 1344, borderRadius: 5 }}>
            <CardHeader
                avatar={base64Image ? <Avatar src={`${base64Image}`} sx={{ width: 186 , height: 186, border: 5}} /> : null}
                sx={{
                    minHeight: 256, 
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                }}
                title={
                    <Typography gutterBottom variant="h5" component="div">
                    {graphData.displayName}
                  </Typography>
                }
            />
            {<CardContent sx={{ minWidth: 768 }}>
                <Grid container spacing={1}>
                    <Grid container xs={4} md={4} alignContent={"top"}>
                        <List>
                            <LocationListItem location={graphData.officeLocation} />
                        </List>
                    </Grid>
                    <Grid container xs={4} md={4} alignContent={"top"}>
                        <List>
                            <JobTitleListItem jobTitle={graphData.jobTitle} />
                            <MailListItem mail={graphData.userPrincipalName} />
                        </List>
                    </Grid>
                    <Grid container xs={4} md={4} alignContent={"top"} >
                        <List>
                            <PhoneListItem phone={graphData.mobilePhone.replace(/\+47/gi, '')} />
                        </List>
                    </Grid>
                </Grid>
            </CardContent>}
        </Card>
    );
};

const NameListItem: React.FC<{ name: string }> = ({ name }) => (
    <ListItem>
        <ListItemAvatar>
            <Avatar>
                <PersonIcon />
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Name" secondary={name} />
    </ListItem>
);

const JobTitleListItem: React.FC<{ jobTitle: string }> = ({ jobTitle }) => (
    <ListItem>
        <ListItemAvatar>
            <Avatar>
                <WorkIcon />
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Stillingsbetegnelse" secondary={jobTitle} />
    </ListItem>
);

const MailListItem: React.FC<{ mail: string }> = ({ mail }) => (
    <ListItem>
        <ListItemAvatar>
            <Avatar>
                <MailIcon />
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary="E-post" secondary={mail} />
    </ListItem>
);

const PhoneListItem: React.FC<{ phone: string }> = ({ phone }) => (
    <ListItem>
        <ListItemAvatar>
            <Avatar>
                <PhoneIcon />
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Telefon" secondary={phone} />
    </ListItem>
);

const LocationListItem: React.FC<{ location: string }> = ({ location }) => (
    <ListItem>
        <ListItemAvatar>
            <Avatar>
                <LocationOnIcon />
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Arbeidssted" secondary={location} />
    </ListItem>
);