import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';

interface SearchBoxProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  handleSearch: () => void;
  inputRef: React.RefObject<HTMLInputElement>;
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));


const SearchBox: React.FC<SearchBoxProps> = ({ searchTerm, setSearchTerm, handleSearch, inputRef }) => {
  return (
    <Box display="flex" alignItems="center" >
      <TextField
        type="text"
        inputRef={inputRef}
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        onKeyPress={e => { if (e.key === 'Enter') handleSearch(); }}
        placeholder="Skriv inn adresse med husnr."
      />
      <SearchIcon onClick={handleSearch} />
    </Box>
  );
}

export default SearchBox;